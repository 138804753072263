import Footer from "apollo-react/components/Footer";
import "./AppFooter.scss";

const footerStyle = {
  maxWidth: "100%",
  height: "auto",
};

const AppFooter = () => {
  return (
    <div className="appfooter">
      <Footer
        buttonProps={[
          {
            label: "Terms of Use",
            href: "https://www.iqvia.com/about-us/terms-of-use",
            target: "_blank",
          },
          {
            label: "Privacy Policy",
            href: "https://www.iqvia.com/about-us/privacy/privacy-policy",
            target: "_blank",
          },
        ]}
        style={footerStyle}
        className="footerWrapper"
      />
    </div>
  );
};

export default AppFooter;
