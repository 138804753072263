import produce from "immer";
import {
  CLEANUP,
  GET_OBJECT_LIST,
  GET_SCHEMA_LIST,
  OBJECT_LIST__FETCH_FAILURE,
  OBJECT_LIST__FETCH_SUCCESS,
  PULL_SNOWFLAKE_OBJECTS_FAILURE,
  PULL_SNOWFLAKE_OBJECTS_ON_DEMAND,
  PULL_SNOWFLAKE_OBJECTS_SUCCESS,
  SCHEMA_LIST_FETCH_FAILURE,
  SCHEMA_LIST_FETCH_SUCCESS,
} from "../../constants";

export const initialState = {
  schemaList: [],
  objectList: [],
  loading: false,
  errmsg: "",
  snowflakePullStatus: "",
};

const ObjectReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case GET_SCHEMA_LIST:
        newState.loading = true;
        newState.schemaList = [];
        newState.errmsg = "";
        break;
      case SCHEMA_LIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.schemaList = action.schemaList;
        break;
      case SCHEMA_LIST_FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.schemaList = [];
        break;
      case GET_OBJECT_LIST:
        newState.loading = true;
        newState.objectList = [];
        newState.errmsg = "";
        break;
      case OBJECT_LIST__FETCH_SUCCESS:
        newState.loading = false;
        newState.objectList = action.objectList;
        break;
      case OBJECT_LIST__FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.objectList = [];
        break;
      case PULL_SNOWFLAKE_OBJECTS_ON_DEMAND:
        newState.snowflakePullStatus = "";
        newState.loading = true;
        newState.errmsg = "";
        break;
      case PULL_SNOWFLAKE_OBJECTS_SUCCESS:
        newState.snowflakePullStatus = "success";
        break;
      case PULL_SNOWFLAKE_OBJECTS_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.snowflakePullStatus = "failed";
        break;
      case CLEANUP:
        newState.schemaList = [];
        newState.objectList = [];
        newState.snowflakePullStatus = "";
        newState.errmsg = "";
        break;
      default:
        break;
    }
  });

export default ObjectReducer;
