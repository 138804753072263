export const PATH = "Path";
export const Success = "success";
export const Warning = "warning";
export const Info = "info";
export const CLEANUP = "CLEANUP";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export const STUDYLIST_DATA = "STUDYLIST_DATA";
export const STUDYLIST_FETCH_SUCCESS = "STUDYLIST_FETCH_SUCCESS";
export const STUDYLIST_FETCH_FAILURE = "STUDYLIST_FETCH_FAILURE";

export const SYSTEMLIST_DATA = "SYSTEMLIST_DATA";
export const SYSTEMLIST_FETCH_SUCCESS = "SYSTEMLIST_FETCH_SUCCESS";
export const SYSTEMLIST_FETCH_FAILURE = "SYSTEMLIST_FETCH_FAILURE";

export const STUDYBOARD_DATA = "STUDYBOARD_DATA";
export const STUDYBOARD_FETCH_SUCCESS = "STUDYBOARD_FETCH_SUCCESS";
export const STUDYBOARD_FETCH_FAILURE = "STUDYBOARD_FETCH_FAILURE";

export const SYSTEMBOARD_DATA = "SYSTEMBOARD_DATA";
export const SYSTEMBOARD_FETCH_SUCCESS = "SYSTEMBOARD_FETCH_SUCCESS";
export const SYSTEMBOARD_FETCH_FAILURE = "SYSTEMBOARD_FETCH_FAILURE";

export const SERVICEACCOUNT_DATA = "SERVICEACCOUNT_DATA";
export const SERVICEACCOUNTDATA_FETCH_SUCCESS =
  "SERVICEACCOUNTDATA_FETCH_SUCCESS";
export const SERVICEACCOUNTDATA_FETCH_FAILURE =
  "SERVICEACCOUNTDATA_FETCH_FAILURE";
export const SERVICEACCOUNTDETAILS = "SERVICEACCOUNTDETAILS";
export const SERVICEACCOUNTSTUDY = "study";
export const SERVICEACCOUNTSYSTEM = "system";
export const SERVICEACCOUNTDETAILS_FETCH_SUCCESS =
  "SERVICEACCOUNTDETAILS_FETCH_SUCCESS";
export const SERVICEACCOUNTDETAILS_FETCH_FAILURE =
  "SERVICEACCOUNTDETAILS_FETCH_FAILURE";
export const SERVICEACCOUNT_STUDY_FETCH_SUCCESS =
  "SERVICEACCOUNT_STUDY_FETCH_SUCCESS";
export const SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS =
  "SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS";
export const SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE =
  "SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE";

export const READ_ONLY_ACCESS_ROLE = "Read-Only Access Role";
export const READ_WRITE_ACCESS_ROLE = "Read-Write Access Role";

export const TENANT_DATA = "TENANT_DATA";
export const TENANTDATA_FETCH_SUCCESS = "TENANTDATA_FETCH_SUCCESS";
export const TENANTDATA_FETCH_FAILURE = "TENANTDATA_FETCH_FAILURE";

export const TENANT_MEMIC = "TENANT_MEMIC";
export const TENANT_MEMIC_SUCCESS = "TENANTMEMIC_FETCH_SUCCESS";
export const TENANT_MEMIC_FAILURE = "TENANTMEMIC_FETCH_FAILURE";

export const PRODUCTS_LIST = "PRODUCTS_LIST";
export const PRODUCTS_LIST_SUCCESS = "PRODUCTS_LIST_SUCCESS";
export const PRODUCTS_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const SPONSOR_DATA = "SPONSOR_DATA";
export const SPONSOR_DATA_FETCH_SUCCESS = "SPONSORDATA_FETCH_SUCCESS";
export const SPONSOR_DATA_FETCH_FAILURE = "SPONSORDATA_FETCH_FAILURE";

export const STUDY_MEMIC = "STUDY_MEMIC";
export const STUDYBOARD_MEMIC_SUCCESS = "STUDYMEMIC_FETCH_SUCCESS";
export const STUDYBOARD_STUDY_SUCCESS = "STUDY_FETCH_SUCCESS";
export const STUDYBOARD_MEMIC_FAILURE = "STUDYMEMIC_FETCH_FAILURE";

export const SPONSOR_PHASE = "SPONSOR_PHASE";
export const SPONSOR_STUDY = "SPONSOR_STUDY";
export const SPONSOR_AREA = "SPONSOR_AREA";
export const SPONSOR_PHASE_FETCH_SUCCESS = "SPONSOR_PHASE_FETCH_SUCCESS";
export const SPONSOR_STUDY_FETCH_SUCCESS = "SPONSOR_STUDY_FETCH_SUCCESS";
export const SPONSOR_AREA_FETCH_SUCCESS = "SPONSOR_AREA_FETCH_SUCCESS";
export const SPONSOR_OPTIONAL_FETCH_FAILURE = "SPONSOROPTIONAL_FETCH_FAILURE";

export const SELECTED_STUDY_DATA = "SELECTED_STUDY_DATA";

export const POLICY_LIST = "POLICY_LIST";
export const POLICY_LIST_SUCCESS = "POLICY_LIST_SUCCESS";
export const POLICY_LIST_FAILURE = "POLICY_LIST_FAILURE";
export const UPDATE_POLICY_STATUS_SUCCESS = "UPDATE_POLICY_STATUS_SUCCESS";
export const UPDATE_POLICY_STATUS = "UPDATE_POLICY_STATUS";
export const UPDATE_POLICY_STATUS_FAILURE = "UPDATE_POLICY_STATUS_FAILURE";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const USER_PERMISSIONS_SUCCESS = "USER_PERMISSIONS_SUCCESS";
export const USER_PERMISSIONS_FAILURE = "USER_PERMISSIONS_FAILURE";

export const VENDOR_LIST = "VENDOR_LIST";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_FAILURE = "VENDOR_LIST_FAILURE";

export const VENS_LIST = "VENS_LIST";
export const VENS_LIST_SUCCESS = "VENS_LIST_SUCCESS";
export const VENS_LIST_FAILURE = "VENS_LIST_FAILURE";

export const CREATE_VENDOR = "CREATE_VENDOR";
export const UPDATE_VENDOR_STATUS = "UPDATE_VENDOR_STATUS";

export const CREAT_USER = "CREATE_USER";
export const UPDATE_USER_STATUS = "UPDATE_VENDOR_STATUS";

export const SHOW_ALERT_TRUE = "SHOW_ALERT_TRUE";
export const SHOW_ALERT_FALSE = "SHOW_ALERT_FALSE";
export const EDIT_STATUS_TRUE = "EDIT_STATUS_TRUE";
export const EDIT_STATUS_FALSE = "EDIT_STATUS_FALSE";

export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const VENDOR_DETAILS_SUCCESS = "VENDOR_DETAILS_SUCCESS";
export const VENDOR_DETAILS_FAILURE = "VENDOR_DETAILS_FAILURE";

export const ROLE_LIST_FETCH = "ROLE_LIST_FETCH";
export const ROLE_LIST_FAILURE = "ROLE_LIST_FAILURE";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const UPDATE_ROLE_STATUS_SUCCESS = "UPDATE_ROLE_STATUS_SUCCESS";
export const UPDATE_ROLE_STATUS = "UPDATE_ROLE_STATUS";
export const UPDATE_ROLE_STATUS_FAILURE = "UPDATE_ROLE_STATUS_FAILURE";

export const STUDY_NOTONBOARDED_STATUS = "STUDY_NOTONBOARDED_STATUS";
export const STUDY_NOTONBOARDED_SUCCESS = "STUDY_NOTONBOARDED_SUCCESS";
export const STUDY_NOTONBOARDED_FAILURE = "STUDY_NOTONBOARDED_FAILURE";
export const SYSTEM_NOTONBOARDED_STATUS = "SYSTEM_NOTONBOARDED_STATUS";
export const SYSTEM_NOTONBOARDED_SUCCESS = "SYSTEM_NOTONBOARDED_SUCCESS";
export const SYSTEM_NOTONBOARDED_FAILURE = "SYSTEM_NOTONBOARDED_FAILURE";
export const FORM_COMPONENT_ACTIVE = "FORM_COMPONENT_ACTIVE";
export const FORM_COMPONENT_IN_ACTIVE = "FORM_COMPONENT_IN_ACTIVE";
export const SHOW_APP_SWITCHER = "SHOW_APP_SWITCHER";
export const HIDE_APP_SWITCHER = "HIDE_APP_SWITCHER";
// API URLS
export const STUDYBOARD_DATA_FETCH = `v1/api/study/list`;
export const SYSTEM_DATA_FETCH = `v1/api/system/list`;
export const STUDYLIST_DATA_FETCH = `v1/api/study/list/dropdown`;
export const SYSTEMLIST_DATA_FETCH = `v1/api/system/list/dropdown`;
export const TENANTLIST_DATA_FETCH = `v1/api/tenant/list`;
export const STUDY_MEMIC_DATA = "v1/api/common/name_std_generator ";
export const SPONSOR_DATA_FETCH = `v1/api/common/tenant-table-where?tableName=sponsor&columnName=spnsr_id,spnsr_nm,spnsr_nm_stnd,spnsr_mnemonic_nm`;
export const SPONSOR_DATA_OPTIONAL = `v1/api/common/tenant-table-where?tableName=cdas_core_lov&columnName=lov_nm&w_columnName=lov_typ&w_columnValue=`;
export const STUDYSEARCH = "v1/api/study/search-study";
export const SAVESTUDYMANUAL = "v1/api/study/createstudy";
export const STUDYSEARCHEXITS = `v1/api/study/study-exists`;
export const SYSTEMSEARCHEXITS = `v1/api/system/exists`;
export const SYSTEMSEARCHEXITSSTUDY = `v1/api/system/exists-study`;
export const EFFECTEDSTUDY = `v1/api/study/search-effectedStudy`;
export const UPDATESTUDYDETAILS = `v1/api/study/updateStudyDetails`;
export const STUDYMDMEXITS = `v1/api/study/mdm-exists`;
export const STUDYSPONSOREXITS = `v1/api/common/tenant-table-where?tableName=sponsor&columnName=spnsr_nm&w_columnName=spnsr_nm&w_columnValue=`;
export const NOT_ONBOARDED_FETCH = "v1/api/study/notonboarded-studies-stat";
export const NOT_ONBOARDED_SYSTEM_FETCH =
  "v1/api/system/notonboarded-system-stat";
export const POLICY_LIST_FETCH = "v1/api/policy/list";
export const UPDATE_POLICY = "v1/api/policy/update/status";
export const ROLES_LIST = "study/select-roles";
export const VENDOR_BASE = "v1/api/vendor";
export const ASSIGN_BASE = "v1/api/study/assign";
export const SYSTEM_ASSIGNMENT_BASE = "v1/api/system/assign";
export const USER_DELETE = "v1/api/users";
export const FETCH_PERMISSIONS = "v1/api/role/getUserRolesPermissions";

export const ROLE_FETCH = "v1/api/role/";
export const UPDATE_ROLE = "v1/api/role/update/status";
export const TENANTSEARCHEXISTS = `v1/api/common/table-where?tableName=tenant&columnName=tenant_nm&w_columnName=tenant_nm&w_columnValue=`;
export const PRODUCTS_LIST_FETCH = "v1/api/policy/products";
export const SERVICEACCOUNTS_LIST_FETCH = "v1/api/service/list";
export const SERVICEACCOUNT_DETAILS_FETCH = "v1/api/service/details";
export const SERVICEACCOUNT_STUDYANDROLE_FETCH =
  "v1/api/service/get-service-account-study-and-role";
// export const TENANT_MEMIC_DATA = "v1/api/common/name_std_generator"

export const OBJECTLEVEL_SCHEMA_FETCH = "v1/api/data-access/schema-list";
export const OBJECTLEVEL_OBJECT_FETCH = "v1/api/data-access/object-list";
export const ROWLEVEL_COLUMN_FETCH = "v1/api/row-level/column-list";
export const SUGGESTION_DATA_FETCH = "v1/api/row-level/suggested-tables";
export const PULL_SNOWFLAKE_OBJECTS = "v1/api/snowflake/pull-data";
export const GROUP_LIST_FETCH = "v1/api/group/group-list";
export const GROUPTYPE_LIST_FETCH = "v1/api/group/get-group-types";
export const FETCH_USERS_BY_PERMISSION = "v1/api/group/get-users-by-permission";
export const FETCH_GROUP_DETAILS = "v1/api/group//get-group-details";

export const baseURL =
  process.env.REACT_APP_API_URL ||
  `${window.location.protocol}//${window.location.hostname}:4000`;
export const API_URL = `${baseURL}/v1/api`;
export const remoteBaseUrl = "https://inttest-cdrws.work.iqvia.com/fsr";

// Product Names
export const CLINICAL_DATA_INGESTION = "Clinical Data Ingestion";
export const CLINICAL_DATA_MAPPER = "Clinical Data Mapper";
export const CLINICAL_DATA_REVIEW = "Data Review";
export const CLINICAL_ANALYTICS = "Clinical Analytics";
export const INTELLIGENT_DOCUMENT_REVIEW = "Intelligent Document Review";
export const DATA_SCIENCE_WORKBENCH = "Data Science Workbench";

export const GET_SCHEMA_LIST = "GET_SCHEMA_LIST";
export const SCHEMA_LIST_FETCH_SUCCESS = "SCHEMA_LIST_FETCH_SUCCESS";
export const SCHEMA_LIST_FETCH_FAILURE = "SCHEMA_LIST_FETCH_FAILURE";
export const GET_OBJECT_LIST = "GET_OBJECT_LIST";
export const OBJECT_LIST__FETCH_FAILURE = "OBJECT_LIST__FETCH_FAILURE";
export const OBJECT_LIST__FETCH_SUCCESS = "OBJECT_LIST__FETCH_SUCCESS";

export const GET_COLUMN_LIST = "GET_COLUMN_LIST";
export const COLUMN_LIST_FETCH_SUCCESS = "COLUMN_LIST_FETCH_SUCCESS";
export const COLUMN_LIST_FETCH_FAILURE = "COLUMN_LIST_FETCH_FAILURE";
export const GET_SUGGESTION_DATA = "GET_SUGGESTION_DATA";
export const SUGGESTION_DATA_FETCH_SUCCESS = "SUGGESTION_DATA_FETCH_SUCCESS";
export const SUGGESTION_DATA_FETCH_FAILURE = "SUGGESTION_DATA_FETCH_FAILURE";

export const PULL_SNOWFLAKE_OBJECTS_ON_DEMAND =
  "PULL_SNOWFLAKE_OBJECTS_ON_DEMAND";
export const PULL_SNOWFLAKE_OBJECTS_SUCCESS = "PULL_SNOWFLAKE_OBJECTS_SUCCESS";
export const PULL_SNOWFLAKE_OBJECTS_FAILURE = "PULL_SNOWFLAKE_OBJECTS_FAILURE";

export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GROUP_LIST_FETCH_SUCCESS = "GROUP_LIST_FETCH_SUCCESS";
export const GROUP_LIST_FETCH_FAILURE = "GROUP_LIST_FETCH_FAILURE";
export const GET_GROUPTYPE_LIST = "GET_GROUPTYPE_LIST";
export const GROUPTYPE_LIST_FETCH_SUCCESS = "GROUPTYPE_LIST_FETCH_SUCCESS";
export const GROUPTYPE_LIST_FETCH_FAILURE = "GROUPTYPE_LIST_FETCH_FAILURE";
export const GET_USERS_BY_PERMISSION = "GET_USERS_BY_PERMISSION";
export const FETCH_USERS_BY_PERMISSION_SUCCESS =
  "FETCH_USERS_BY_PERMISSION_SUCCESS";
export const FETCH_USERS_BY_PERMISSION_FAILURE =
  "FETCH_USERS_BY_PERMISSION_FAILURE";
export const GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
export const FETCH_GROUP_DETAILS_SUCCESS = "FETCH_GROUP_DETAILS_SUCCESS";
export const FETCH_GROUP_DETAILS_FAILURE = "FETCH_GROUP_DETAILS_FAILURE";
