import { takeEvery, takeLatest } from "redux-saga/effects";
// import axios from "axios";
import {
  STUDYBOARD_DATA,
  SPONSOR_DATA,
  STUDYLIST_DATA,
  SYSTEMLIST_DATA,
  STUDY_NOTONBOARDED_STATUS,
  SYSTEM_NOTONBOARDED_STATUS,
  SYSTEMBOARD_DATA,
  TENANT_DATA,
  POLICY_LIST,
  UPDATE_POLICY_STATUS,
  GET_VENDOR_DETAILS,
  VENDOR_LIST,
  VENS_LIST,
  ROLE_LIST_FETCH,
  UPDATE_ROLE_STATUS,
  GET_USER_PERMISSIONS,
  SPONSOR_STUDY,
  SPONSOR_PHASE,
  SPONSOR_AREA,
  STUDY_MEMIC,
  TENANT_MEMIC,
  PRODUCTS_LIST,
  SERVICEACCOUNT_DATA,
  SERVICEACCOUNTDETAILS,
  SERVICEACCOUNTSTUDY,
  SERVICEACCOUNTSYSTEM,
  GET_SCHEMA_LIST,
  GET_OBJECT_LIST,
  GET_COLUMN_LIST,
  GET_SUGGESTION_DATA,
  PULL_SNOWFLAKE_OBJECTS_ON_DEMAND,
  GET_GROUP_LIST,
  GET_GROUPTYPE_LIST,
  GET_USERS_BY_PERMISSION,
  GET_GROUP_DETAILS,
} from "../../constants";

import {
  fetchStudyboardData,
  fetchNotOnStudyboardStatus,
  fetchSponsorData,
  fetchStudylistData,
  fetchSponsorOptionalData,
  fetchMemicData,
  fetchTenantData,
} from "./studyboard.saga";
import { fetchTenantMemicData, fetchProducts } from "./tenant.saga";
import { fetchRoles, updateRoleStatus } from "./role.saga";
import { fetchPolicies, updatePolicyStatus } from "./policy.saga";
import { fetchPermissions } from "./user.saga";
import {
  fetchVendorList,
  fetchVendorDetails,
  fetchENSList,
} from "./vendor.saga";
import {
  fetchServiceAccounts,
  fetchServiceAccountDetails,
  fetchServiceAccountStudyandRole,
} from "./service.saga";
import {
  fetchSchemas,
  fetchObjects,
  fetchSnowflakeObjectsOnDemand,
} from "./object.saga";
import { fetchColumns, fetchSuggestionData } from "./rowLevel.saga";
import {
  fetchGroupdetailsByid,
  fetchGroups,
  fetchGroupTypes,
  fetchUsersByPermission,
} from "./group.saga";

function* cdasCoreSaga() {
  yield takeEvery(STUDYBOARD_DATA, fetchStudyboardData);
  yield takeEvery(SYSTEMBOARD_DATA, fetchStudyboardData);
  yield takeEvery(TENANT_DATA, fetchTenantData);
  yield takeEvery(STUDYLIST_DATA, fetchStudylistData);
  yield takeEvery(SYSTEMLIST_DATA, fetchStudylistData);

  yield takeEvery(SPONSOR_DATA, fetchSponsorData);
  yield takeEvery(SPONSOR_STUDY, fetchSponsorOptionalData);
  yield takeEvery(SPONSOR_PHASE, fetchSponsorOptionalData);
  yield takeEvery(SPONSOR_AREA, fetchSponsorOptionalData);
  yield takeEvery(STUDY_MEMIC, fetchMemicData);
  yield takeLatest(STUDY_NOTONBOARDED_STATUS, fetchNotOnStudyboardStatus);
  yield takeLatest(SYSTEM_NOTONBOARDED_STATUS, fetchNotOnStudyboardStatus);
  yield takeEvery(POLICY_LIST, fetchPolicies);
  yield takeEvery(UPDATE_POLICY_STATUS, updatePolicyStatus);
  yield takeEvery(VENDOR_LIST, fetchVendorList);
  yield takeEvery(VENS_LIST, fetchENSList);
  yield takeEvery(GET_VENDOR_DETAILS, fetchVendorDetails);
  yield takeEvery(ROLE_LIST_FETCH, fetchRoles);
  yield takeEvery(UPDATE_ROLE_STATUS, updateRoleStatus);
  yield takeEvery(GET_USER_PERMISSIONS, fetchPermissions);
  yield takeEvery(PRODUCTS_LIST, fetchProducts);
  yield takeEvery(SERVICEACCOUNT_DATA, fetchServiceAccounts);
  yield takeEvery(SERVICEACCOUNTDETAILS, fetchServiceAccountDetails);
  yield takeEvery(SERVICEACCOUNTSTUDY, fetchServiceAccountStudyandRole);
  yield takeEvery(SERVICEACCOUNTSYSTEM, fetchServiceAccountStudyandRole);

  yield takeEvery(TENANT_MEMIC, fetchTenantMemicData);

  yield takeEvery(GET_SCHEMA_LIST, fetchSchemas);
  yield takeEvery(GET_OBJECT_LIST, fetchObjects);
  yield takeEvery(GET_COLUMN_LIST, fetchColumns);
  yield takeEvery(GET_SUGGESTION_DATA, fetchSuggestionData);
  yield takeEvery(
    PULL_SNOWFLAKE_OBJECTS_ON_DEMAND,
    fetchSnowflakeObjectsOnDemand
  );
  yield takeEvery(GET_GROUP_LIST, fetchGroups);
  yield takeEvery(GET_GROUPTYPE_LIST, fetchGroupTypes);
  yield takeEvery(GET_USERS_BY_PERMISSION, fetchUsersByPermission);
  yield takeEvery(GET_GROUP_DETAILS, fetchGroupdetailsByid);
}

export default cdasCoreSaga;
