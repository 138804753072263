import produce from "immer";
// import moment from "moment";

import {
  STUDYBOARD_FETCH_SUCCESS,
  STUDYBOARD_DATA,
  SPONSOR_DATA,
  SPONSOR_DATA_FETCH_SUCCESS,
  STUDY_NOTONBOARDED_STATUS,
  STUDY_NOTONBOARDED_SUCCESS,
  STUDY_NOTONBOARDED_FAILURE,
  SYSTEM_NOTONBOARDED_STATUS,
  SYSTEM_NOTONBOARDED_SUCCESS,
  SYSTEM_NOTONBOARDED_FAILURE,
  SELECTED_STUDY_DATA,
  SPONSOR_AREA,
  SPONSOR_AREA_FETCH_SUCCESS,
  SPONSOR_PHASE_FETCH_SUCCESS,
  SPONSOR_STUDY_FETCH_SUCCESS,
  STUDYLIST_DATA,
  STUDYLIST_FETCH_SUCCESS,
  SYSTEMLIST_FETCH_SUCCESS,
  STUDY_MEMIC,
  STUDYBOARD_MEMIC_SUCCESS,
  STUDYBOARD_STUDY_SUCCESS,
  SYSTEMBOARD_FETCH_SUCCESS,
  TENANTDATA_FETCH_SUCCESS,
  CLEANUP,
} from "../../constants";

export const initialState = {
  studyboardData: [],
  systemboardData: [],
  tenantData: [],
  studylistData: [],
  systemlistData: [],
  sponsorData: [],
  sponsorPhase: [],
  sponsorStudy: [],
  sponsorArea: [],
  sposormemic: "",
  studymemic: "",
  notOnBoardedStudyStatus: {},
  loading: false,
  exportStudy: null,
  selectedStudy: {},
  uniquePhase: [],
  uniqueProtocolStatus: [],
  uniqueThbtcArea: [],
  uniqueObs: [],
  data: "",
  studyboardFetchSuccess: false,
};

const StudyBoardReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case STUDYBOARD_DATA:
        newState.loading = true;
        break;

      case STUDYLIST_DATA:
        newState.loading = true;
        newState.studylistFetchSuccess = false;
        break;

      case STUDY_NOTONBOARDED_STATUS:
        newState.loading = true;
        break;

      case SYSTEM_NOTONBOARDED_STATUS:
        newState.loading = true;
        break;
      case STUDYBOARD_FETCH_SUCCESS:
        newState.loading = false;
        newState.studyboardData = action.studyboardData;
        newState.uniquePhase = action.uniqurePhase;
        newState.uniqueProtocolStatus = action.uniqueProtocolStatus;
        newState.uniqueThbtcArea = action.uniqueThbtcArea;
        newState.uniqueObs = action.uniqueObs;
        newState.studyboardFetchSuccess = true;
        newState.studyboardFetchFailure = false;
        break;

      case SYSTEMBOARD_FETCH_SUCCESS:
        newState.loading = false;
        newState.systemboardData = action.studyboardData;
        newState.systemboardFetchSuccess = true;
        newState.systemboardFetchFailure = false;
        newState.uniqueObs = action.uniqueObs;
        break;

      case TENANTDATA_FETCH_SUCCESS:
        newState.loading = false;
        newState.tenantData = action.studyboardData;
        newState.tenantDataFetchSuccess = true;
        newState.tenantDataFetchFailure = false;
        newState.uniqueObs = action.uniqueObs;
        break;

      case STUDYLIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.studylistData = action.studyboardData;
        newState.uniqueObs = action.uniqueObs;
        newState.uniquePhase = action.uniqurePhase;
        newState.uniqueProtocolStatus = action.uniqueProtocolStatus;
        newState.uniqueThbtcArea = action.uniqueThbtcArea;
        newState.studylistFetchSuccess = true;
        newState.studylistFetchFailure = false;
        break;

      case SYSTEMLIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.systemlistData = action.studyboardData;
        newState.systemlistFetchSuccess = true;
        newState.systemlistFetchFailure = false;
        newState.uniqueObs = action.uniqueObs;

        break;

      case SPONSOR_DATA:
        newState.loading = true;
        break;
      case SPONSOR_AREA:
        newState.loading = true;
        break;
      case STUDY_MEMIC:
        newState.loading = true;
        break;
      case STUDYBOARD_MEMIC_SUCCESS:
        newState.sposormemic = action.sposormemic;
        break;
      case STUDYBOARD_STUDY_SUCCESS:
        newState.studymemic = action.studymemic;
        break;
      case SPONSOR_AREA_FETCH_SUCCESS:
        newState.sponsorArea = action.sponsorArea;
        break;
      case SPONSOR_PHASE_FETCH_SUCCESS:
        newState.sponsorPhase = action.sponsorPhase;
        break;
      case SPONSOR_STUDY_FETCH_SUCCESS:
        newState.sponsorStudy = action.sponsorStudy;
        break;
      case SPONSOR_DATA_FETCH_SUCCESS:
        newState.sponsorData = action.sponsorData;
        break;

      case STUDY_NOTONBOARDED_SUCCESS:
        newState.loading = false;
        newState.notOnBoardedStudyStatus = action.notOnBoardedStudyStatus;
        break;

      case SYSTEM_NOTONBOARDED_SUCCESS:
        newState.loading = false;
        newState.notOnBoardedStudyStatus = action.notOnBoardedStudyStatus;
        break;

      case SELECTED_STUDY_DATA:
        newState.selectedStudy = action.study;
        break;

      case STUDY_NOTONBOARDED_FAILURE:
        newState.loading = true;
        break;
      case SYSTEM_NOTONBOARDED_FAILURE:
        newState.loading = true;
        break;
      case CLEANUP:
        newState.studyboardData = [];
        newState.systemboardData = [];
        newState.studylistData = [];
        newState.systemlistData = [];
        break;
      default:
        break;
    }
  });

export default StudyBoardReducer;
