import produce from "immer";
import {
  FETCH_GROUP_DETAILS_FAILURE,
  FETCH_GROUP_DETAILS_SUCCESS,
  FETCH_USERS_BY_PERMISSION_FAILURE,
  FETCH_USERS_BY_PERMISSION_SUCCESS,
  GET_GROUPTYPE_LIST,
  GET_GROUP_DETAILS,
  GET_GROUP_LIST,
  GET_USERS_BY_PERMISSION,
  GROUPTYPE_LIST_FETCH_FAILURE,
  GROUPTYPE_LIST_FETCH_SUCCESS,
  GROUP_LIST_FETCH_FAILURE,
  GROUP_LIST_FETCH_SUCCESS,
} from "../../constants";

export const initialState = {
  loading: false,
  groupList: [],
  groupTypeList: [],
  errmsg: "",
  userList: [],
  groupDetails: {},
  groupMembers: [],
};

const GroupReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case GET_GROUP_LIST:
        newState.loading = true;
        newState.groupList = [];
        newState.errmsg = "";
        break;
      case GROUP_LIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.groupList = action.groupList;
        break;
      case GROUP_LIST_FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.groupList = [];
        break;
      case GET_GROUPTYPE_LIST:
        newState.loading = true;
        newState.groupTypeList = [];
        newState.errmsg = "";
        break;
      case GROUPTYPE_LIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.groupTypeList = action.groupTypeList;
        break;
      case GROUPTYPE_LIST_FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        break;
      case GET_USERS_BY_PERMISSION:
        newState.loading = true;
        newState.userList = [];
        newState.errmsg = "";
        break;
      case FETCH_USERS_BY_PERMISSION_SUCCESS:
        newState.loading = false;
        newState.userList = action.userList;
        break;
      case FETCH_USERS_BY_PERMISSION_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        break;
      case GET_GROUP_DETAILS:
        newState.loading = true;
        newState.groupDetails = {};
        newState.groupMembers = [];
        newState.errmsg = "";
        break;
      case FETCH_GROUP_DETAILS_SUCCESS:
        newState.loading = false;
        newState.groupDetails = action.groupDetails;
        newState.groupMembers = action.groupMembers;
        break;
      case FETCH_GROUP_DETAILS_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        break;
      default:
        break;
    }
  });
export default GroupReducer;
