import { put, call } from "redux-saga/effects";
import axios from "axios";

import {
  baseURL,
  SERVICEACCOUNTDATA_FETCH_FAILURE,
  SERVICEACCOUNTDATA_FETCH_SUCCESS,
  SERVICEACCOUNTS_LIST_FETCH,
  SERVICEACCOUNT_DETAILS_FETCH,
  SERVICEACCOUNT_STUDYANDROLE_FETCH,
  SERVICEACCOUNTDETAILS_FETCH_FAILURE,
  SERVICEACCOUNTDETAILS_FETCH_SUCCESS,
  SERVICEACCOUNT_STUDY_FETCH_SUCCESS,
  SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE,
  SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS,
} from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export function* fetchServiceAccounts() {
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${SERVICEACCOUNTS_LIST_FETCH}`
    );
    yield put({
      type: SERVICEACCOUNTDATA_FETCH_SUCCESS,
      productList: fetchData.data.data,
    });
  } catch (e) {
    yield put({ type: SERVICEACCOUNTDATA_FETCH_FAILURE, message: e.message });
  }
}

export function* fetchServiceAccountDetails(action) {
  const { serviceAccountId } = action;
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${SERVICEACCOUNT_DETAILS_FETCH}`,
      { params: { serviceAccountId } }
    );
    yield put({
      type: SERVICEACCOUNTDETAILS_FETCH_SUCCESS,
      accountDetails: fetchData.data.data,
    });
  } catch (e) {
    yield put({
      type: SERVICEACCOUNTDETAILS_FETCH_FAILURE,
      message: e.message,
    });
  }
}

export function* fetchServiceAccountStudyandRole(action) {
  const { type, accountId } = action;
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${SERVICEACCOUNT_STUDYANDROLE_FETCH}`,
      { params: { type, accountId } }
    );
    if (action.type === "study") {
      yield put({
        type: SERVICEACCOUNT_STUDY_FETCH_SUCCESS,
        serviceAccountStudies: fetchData.data.data,
      });
    } else {
      yield put({
        type: SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS,
        serviceAccountSystems: fetchData.data.data,
      });
    }
  } catch (e) {
    yield put({
      type: SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE,
      message: e.message,
    });
  }
}
