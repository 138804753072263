import { put, call } from "redux-saga/effects";
import axios from "axios";
import {
  baseURL,
  STUDYBOARD_DATA_FETCH,
  SPONSOR_DATA_FETCH,
  SPONSOR_DATA_FETCH_SUCCESS,
  SPONSOR_DATA_FETCH_FAILURE,
  STUDYBOARD_FETCH_SUCCESS,
  STUDYBOARD_FETCH_FAILURE,
  NOT_ONBOARDED_FETCH,
  NOT_ONBOARDED_SYSTEM_FETCH,
  STUDY_NOTONBOARDED_SUCCESS,
  STUDY_NOTONBOARDED_FAILURE,
  SPONSOR_DATA_OPTIONAL,
  SPONSOR_PHASE_FETCH_SUCCESS,
  SPONSOR_OPTIONAL_FETCH_FAILURE,
  SPONSOR_AREA_FETCH_SUCCESS,
  SPONSOR_STUDY_FETCH_SUCCESS,
  STUDY_MEMIC_DATA,
  STUDYBOARD_MEMIC_FAILURE,
  STUDYBOARD_MEMIC_SUCCESS,
  STUDYBOARD_STUDY_SUCCESS,
  SYSTEM_NOTONBOARDED_SUCCESS,
  SYSTEM_NOTONBOARDED_FAILURE,
  SYSTEM_DATA_FETCH,
  SYSTEMBOARD_FETCH_SUCCESS,
  SYSTEMBOARD_FETCH_FAILURE,
  STUDYLIST_DATA_FETCH,
  SYSTEMLIST_DATA_FETCH,
  TENANTLIST_DATA_FETCH,
  STUDYLIST_FETCH_SUCCESS,
  SYSTEMLIST_FETCH_SUCCESS,
  STUDYLIST_FETCH_FAILURE,
  SYSTEMLIST_FETCH_FAILURE,
  TENANTDATA_FETCH_SUCCESS,
  TENANTDATA_FETCH_FAILURE,
} from "../../constants";
import { getUserId } from "../../utils";

const UrlLIst = {
  study: STUDYBOARD_DATA_FETCH,
  system: SYSTEM_DATA_FETCH,
  tenant: TENANTLIST_DATA_FETCH,
};

const successList = {
  study: STUDYBOARD_FETCH_SUCCESS,
  system: SYSTEMBOARD_FETCH_SUCCESS,
  tenant: TENANTDATA_FETCH_SUCCESS,
};

const failureList = {
  study: STUDYBOARD_FETCH_FAILURE,
  system: SYSTEMBOARD_FETCH_FAILURE,
  tenant: TENANTDATA_FETCH_FAILURE,
};
// eslint-disable-next-line import/prefer-default-export
export function* fetchStudyboardData(action) {
  try {
    // eslint-disable-next-line
    const fetchSBData = yield call(
      axios.post,
      `${baseURL}/${UrlLIst[action.data]}`,
      {
        usr_id: getUserId(),
      }
    );
    yield put({
      type: successList[action.data],
      studyboardData: fetchSBData.data.data.studyData,
      uniqurePhase: fetchSBData.data.data.uniquePhase,
      uniqueProtocolStatus: fetchSBData.data.data.uniqueProtocolStatus,
      uniqueObs: fetchSBData.data.data.uniqueObs,
      uniqueThbtcArea: fetchSBData.data.data.uniqueThbtcArea,
    });
  } catch (e) {
    yield put({ type: failureList[action.data], message: e.message });
  }
}

export function* fetchTenantData() {
  try {
    // eslint-disable-next-line
    const fetchSBData = yield call(
      axios.post,
      `${baseURL}/${TENANTLIST_DATA_FETCH}`,
      {
        usr_id: getUserId(),
      }
    );
    yield put({
      type: TENANTDATA_FETCH_SUCCESS,
      studyboardData: fetchSBData.data.message,
    });
  } catch (e) {
    yield put({ type: TENANTDATA_FETCH_FAILURE, message: e.message });
  }
}

export function* fetchStudylistData(action) {
  const studyCheck = action.data === "study";
  const mainUrl = studyCheck ? STUDYLIST_DATA_FETCH : SYSTEMLIST_DATA_FETCH;
  const actionType = studyCheck
    ? STUDYLIST_FETCH_SUCCESS
    : SYSTEMLIST_FETCH_SUCCESS;
  const failure = studyCheck
    ? STUDYLIST_FETCH_FAILURE
    : SYSTEMLIST_FETCH_FAILURE;
  try {
    // eslint-disable-next-line
    const fetchSBData = yield call(axios.post, `${baseURL}/${mainUrl}`, {
      usr_id: getUserId(),
    });
    yield put({
      type: actionType,
      studyboardData: fetchSBData.data.data.studyData,
      uniqurePhase: fetchSBData.data.data.uniquePhase,
      uniqueProtocolStatus: fetchSBData.data.data.uniqueProtocolStatus,
      uniqueObs: fetchSBData.data.data.uniqueObs,
      uniqueThbtcArea: fetchSBData.data.data.uniqueThbtcArea,
    });
  } catch (e) {
    yield put({ type: failure, message: e.message });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchMemicData(params) {
  const { data } = params;
  if (data.table_name === "") {
    yield put({
      type: STUDYBOARD_STUDY_SUCCESS,
      studymemic: "",
    });
    return;
  }
  try {
    const fetchSBData = yield call(
      axios.post,
      `${baseURL}/${STUDY_MEMIC_DATA}`,
      data
    );
    if (data.table_name === "study") {
      yield put({
        type: STUDYBOARD_STUDY_SUCCESS,
        studymemic: fetchSBData.data.data,
      });
      return;
    }

    yield put({
      type: STUDYBOARD_MEMIC_SUCCESS,
      sposormemic: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({ type: STUDYBOARD_MEMIC_FAILURE, message: e.message });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchSponsorData() {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${SPONSOR_DATA_FETCH}`,
      {}
    );
    yield put({
      type: SPONSOR_DATA_FETCH_SUCCESS,
      sponsorData: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({ type: SPONSOR_DATA_FETCH_FAILURE, message: e.message });
  }
}

export function* fetchSponsorOptionalData(action) {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${SPONSOR_DATA_OPTIONAL}${action.data}`,
      {}
    );
    if (action.data === "Therapeutic_Area") {
      yield put({
        type: SPONSOR_AREA_FETCH_SUCCESS,
        sponsorArea: fetchSBData.data.data,
      });
    } else if (action.data === "Study_Status") {
      yield put({
        type: SPONSOR_STUDY_FETCH_SUCCESS,
        sponsorStudy: fetchSBData.data.data,
      });
    } else {
      yield put({
        type: SPONSOR_PHASE_FETCH_SUCCESS,
        sponsorPhase: fetchSBData.data.data,
      });
    }
  } catch (e) {
    yield put({ type: SPONSOR_OPTIONAL_FETCH_FAILURE, message: e.message });
  }
}
export function* fetchNotOnStudyboardStatus(action) {
  const studyCheck = action.data === "study";
  const mainUrl = studyCheck ? NOT_ONBOARDED_FETCH : NOT_ONBOARDED_SYSTEM_FETCH;
  const failure = studyCheck
    ? STUDY_NOTONBOARDED_FAILURE
    : SYSTEM_NOTONBOARDED_FAILURE;
  const actionType = studyCheck
    ? STUDY_NOTONBOARDED_SUCCESS
    : SYSTEM_NOTONBOARDED_SUCCESS;
  try {
    const studyboardStatus = yield call(axios.get, `${baseURL}/${mainUrl}`);
    yield put({
      type: actionType,
      notOnBoardedStudyStatus: studyboardStatus.data.data,
    });
  } catch (e) {
    yield put({ type: failure, message: e.message });
  }
}
