import axios from "axios";
import { call, put } from "redux-saga/effects";
import {
  COLUMN_LIST_FETCH_FAILURE,
  COLUMN_LIST_FETCH_SUCCESS,
  ROWLEVEL_COLUMN_FETCH,
  SUGGESTION_DATA_FETCH,
  SUGGESTION_DATA_FETCH_FAILURE,
  SUGGESTION_DATA_FETCH_SUCCESS,
  baseURL,
} from "../../constants";

export function* fetchColumns(action) {
  const { userId, objectId } = action;
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${ROWLEVEL_COLUMN_FETCH}`,
      {
        params: { userId, objectId },
      }
    );
    yield put({
      type: COLUMN_LIST_FETCH_SUCCESS,
      columnList: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({
      type: COLUMN_LIST_FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchSuggestionData(action) {
  const { schemaId } = action;
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${SUGGESTION_DATA_FETCH}`,
      {
        params: { schema_id: schemaId },
      }
    );
    yield put({
      type: SUGGESTION_DATA_FETCH_SUCCESS,
      suggestionData: fetchSBData.data.data.suggestionData,
      columns: fetchSBData.data.data.columns,
    });
  } catch (e) {
    yield put({
      type: SUGGESTION_DATA_FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}
