import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_GROUP_DETAILS,
  FETCH_GROUP_DETAILS_SUCCESS,
  FETCH_USERS_BY_PERMISSION,
  FETCH_USERS_BY_PERMISSION_FAILURE,
  FETCH_USERS_BY_PERMISSION_SUCCESS,
  GROUPTYPE_LIST_FETCH,
  GROUPTYPE_LIST_FETCH_FAILURE,
  GROUPTYPE_LIST_FETCH_SUCCESS,
  GROUP_LIST_FETCH,
  GROUP_LIST_FETCH_FAILURE,
  GROUP_LIST_FETCH_SUCCESS,
  baseURL,
} from "../../constants";

export function* fetchGroups() {
  try {
    const fetchSBData = yield call(axios.get, `${baseURL}/${GROUP_LIST_FETCH}`);
    yield put({
      type: GROUP_LIST_FETCH_SUCCESS,
      groupList: fetchSBData.data.groupList,
    });
  } catch (e) {
    yield put({
      type: GROUP_LIST_FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchGroupTypes() {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${GROUPTYPE_LIST_FETCH}`
    );
    yield put({
      type: GROUPTYPE_LIST_FETCH_SUCCESS,
      groupTypeList: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({
      type: GROUPTYPE_LIST_FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchUsersByPermission(action) {
  try {
    const fetchSBData = yield call(
      axios.post,
      `${baseURL}/${FETCH_USERS_BY_PERMISSION}`,
      {
        featureName: action.featureName,
        permissionType: action.permissionType,
      }
    );
    yield put({
      type: FETCH_USERS_BY_PERMISSION_SUCCESS,
      userList: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({
      type: FETCH_USERS_BY_PERMISSION_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchGroupdetailsByid(action) {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${FETCH_GROUP_DETAILS}/${action?.groupId}`
    );
    yield put({
      type: FETCH_GROUP_DETAILS_SUCCESS,
      groupDetails: fetchSBData.data.data.group_details,
      groupMembers: fetchSBData.data.data.group_members,
    });
  } catch (e) {
    yield put({
      type: FETCH_USERS_BY_PERMISSION_FAILURE,
      message: e.response.data.message,
    });
  }
}
