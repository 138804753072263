/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
import makeStyles from "@mui/styles/makeStyles";
import App from "apollo-react-icons/App";
import Question from "apollo-react-icons/Question";
import { neutral7 } from "apollo-react/colors";
import Backdrop from "apollo-react/components/Backdrop";
import Banner from "apollo-react/components/Banner";
import Button from "apollo-react/components/Button";
import CircularProgress from "apollo-react/components/CircularProgress";
import MenuItem from "apollo-react/components/MenuItem";
import Modal from "apollo-react/components/Modal";
import NavigationBar from "apollo-react/components/NavigationBar";
import Select from "apollo-react/components/Select";
import Typography from "apollo-react/components/Typography";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router";
// eslint-disable-next-line import/named
import {
  getRolesPermissions,
  getTenants,
  submitTenantsID,
  userLogOut,
} from "../../services/ApiServices";
import {
  editStatusFalse,
  hideAppSwitcher,
  showAlert,
} from "../../store/actions/AlertActions";
// eslint-disable-next-line import/named
import {
  getTenantId,
  matchAppUrl,
  setCookiesOnWeb,
  setTenantId,
} from "../../utils/index";
import { AppContext } from "../Providers/AppProvider";
import { MessageContext } from "../Providers/MessageProvider";
import NavigationPanel from "./NavigationPanel/NavigationPanel";
import ProfileMenu from "./ProfileMenu/ProfileMenu";

const styles = {
  haveAccess: {
    color: "#e41e1e",
  },
  notapplied: {
    color: "yellow",
  },
  root: {
    display: "flex",
    height: 400,
    boxSizing: "content-box",
  },
  panelTitle: {
    padding: "24px 24px 16px 24px",
    fontWeight: 600,
  },
  card: {
    margin: "8px 24px",
    cursor: "pointer",
  },
  cardHighlight: {
    backgroundColor: "#d8e7fe",
  },
  bold: {
    fontWeight: 600,
  },
  cardSubtitle: {
    color: neutral7,
    lineHeight: "24px",
  },
  page: {
    padding: 24,
  },
  panelContent: {
    overflow: "auto",
    height: 333,
    minWidth: 300,
  },
  centerAligned: {
    display: "flex",
    alignItems: "center",
  },
  appIcon: {
    fontSize: 24,
    color: "#fff",
    cursor: "pointer",
  },
  helpIcon: {},
  navLogo: {
    color: "white",
    marginRight: 24,
    cursor: "pointer",
    zIndex: 2,
    whiteSpace: "nowrap",
  },
  fullNavHeight: {
    height: "100%",
  },
};

const useStyles = makeStyles(styles);
const STUDY_SETUP = "Study Setup";
const USER_MANAGEMENT = "User Management";
const TABLE_ACCESS_CONTROL = "Table Access Control";
const ROW_ACCESS_CONTROL = "Row Access Control";
const TENANT_MANAGEMENT = "Tenant Management";
const SERVICE_ACCOUNT_SETUP = "Service Account Setup";

const AppHeader = ({ history, setLoggedIn }) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const messageContext = useContext(MessageContext);
  const [panelOpen, setpanelOpen] = useState(true);
  const [notLoggedOutErr, setNotLoggedOutErr] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenantName, setTenantName] = useState("");
  const [tenatStdName, setTenantStdName] = useState("");
  const [showVersionModal, setShowVersionModal] = useState(false);
  const { permissions } = appContext.user;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const alertStore = useSelector((state) => state.Alert);
  const updatePathName = pathname.split("/");

  useEffect(() => {
    setCookiesOnWeb();
  }, []);

  const onPanelClose = () => {
    setpanelOpen(false);
  };
  const updateTenantId = () => {
    setTenantId(tenatStdName);
    axios.defaults.headers.common.tenant = tenatStdName;
    submitTenantsID();
    setModalOpen(false);
    setNavigationOpen(true);
    getPermisions();
  };

  const getPermisions = async () => {
    if (!matchAppUrl()) {
      LogOut();
      return false;
    }
    if (permissions.length === 0) {
      let uniquePermissions = [];
      const data = await getRolesPermissions();
      if (data?.status === 401) {
        LogOut();
        return false;
      }
      if (data?.message === "Something went wrong") {
        messageContext.showErrorMessage(
          `There was an issue authorizing your login information. Please contact your Administrator.`
        );
      } else if (data?.status === 500) {
        messageContext.showErrorMessage(
          `There was an internal issue. Please contact your Administrator.`
        );
      } else {
        uniquePermissions = Array?.from(
          data
            ?.reduce(
              (acc, { categoryName, featureName, allowedPermission }) => {
                const current = acc?.get(featureName) || {
                  allowedPermission: [],
                };
                return acc?.set(featureName, {
                  ...current,
                  categoryName,
                  featureName,
                  allowedPermission: [
                    ...current?.allowedPermission,
                    allowedPermission,
                  ],
                });
              },
              new Map()
            )
            ?.values()
        );
        appContext.updateUser({
          permissions: uniquePermissions,
        });
      }
    }
  };

  const CancelConfirmModal = React.memo(
    ({ confirmModalOpen, moveBack, moveAhead }) => {
      return (
        <Modal
          open={confirmModalOpen}
          onClose={moveBack}
          disableBackdropClick={true}
          className="save-confirm"
          variant="warning"
          title="Lose your work?"
          message="All unsaved changes will be lost."
          buttonProps={[
            {
              label: "Keep editing",
              onClick: moveBack,
              // disabled: loading,
            },
            {
              label: "Leave without saving",
              onClick: moveAhead,
              // disabled: loading,
            },
          ]}
          id="neutral"
        />
      );
    }
  );

  const moveBack = () => {
    setConfirmModalOpen(false);
  };

  const moveAhead = () => {
    setConfirmModalOpen(false);
    ConfirmLogOut();
  };

  const ConfirmLogOut = async () => {
    setOpen(true);
    const isLogout = await userLogOut();
    if (isLogout) {
      setLoggedIn(false);
      history.push("/logout");
      setOpen(false);
    } else {
      setNotLoggedOutErr(true);
      setOpen(false);
    }
    dispatch(editStatusFalse());
  };

  const LogOut = () => {
    if (alertStore.editStatus) setConfirmModalOpen(true);
    else ConfirmLogOut();
  };

  const getTenantsWithUserId = async () => {
    const data = await getTenants();
    if (data?.message === "Something went wrong") {
      setModalOpen(false);
      messageContext.showErrorMessage(
        `There was an issue authorizing your login information. Please contact your Administrator.`
      );
    } else if (data?.status === 500) {
      setModalOpen(false);
      messageContext.showErrorMessage(
        `There was an internal issue. Please contact your Administrator.`
      );
    } else if (data?.data?.message === "No associated tenant found!!") {
      setModalOpen(false);
      LogOut();
    } else if (data.length > 1) {
      setTenantData(data);
      setModalOpen(true);
    } else {
      const tenantName = data[0].tenant_nm;
      setTenantName(tenantName);
      setTenantId(tenantName);
      axios.defaults.headers.common.tenant = tenantName;
      submitTenantsID(tenantName);
      setNavigationOpen(true);
      setModalOpen(false);
      getPermisions();
    }
  };

  useEffect(() => {
    const tenantName = getTenantId("tenant");
    if (tenantName === "") {
      getTenantsWithUserId();
    } else {
      getPermisions();
      setNavigationOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelection = (e) => {
    setTenantName(e.target.value);
    tenantData.filter((x) =>
      x.tenant_nm === e.target.value ? setTenantStdName(x.tenant_nm_stnd) : ""
    );
  };

  const checkAccess = (name) => {
    if (permissions.length > 0) {
      const hasAccess = permissions.some((per) =>
        per.featureName.includes(name)
      );
      return hasAccess;
    }
    return false;
  };

  const menuItems = [
    {
      featureName: "Launchpad",
      text: "Launchpad",
      pathname: "/launchpad",
      haveAccess: true,
    },
    {
      featureName: "Analytics",
      text: "Analytics",
      pathname: "/analytics",
      haveAccess: checkAccess("Analytics"),
    },
    {
      featureName: STUDY_SETUP,
      text: STUDY_SETUP,
      pathname: "/study-setup",
      haveAccess: checkAccess(STUDY_SETUP),
    },
    {
      featureName: USER_MANAGEMENT,
      text: USER_MANAGEMENT,
      pathname: "/user-management",
      haveAccess: checkAccess(USER_MANAGEMENT),
    },
    {
      dataSecurity: [
        {
          featureName: TABLE_ACCESS_CONTROL,
          text: TABLE_ACCESS_CONTROL,
          pathname: "/table-access-control",
          haveAccess: checkAccess(TABLE_ACCESS_CONTROL),
        },
        {
          featureName: ROW_ACCESS_CONTROL,
          text: ROW_ACCESS_CONTROL,
          pathname: "/study-identifier-setup",
          haveAccess: checkAccess(ROW_ACCESS_CONTROL),
        },
      ],
    },
    {
      menuItems: [
        {
          featureName: "Policy Management",
          text: "Policy Management",
          pathname: "/policy-management",
          haveAccess: checkAccess("Policy management "),
        },
        {
          featureName: "Role Management",
          text: "Role Management",
          pathname: "/role-management",
          haveAccess: checkAccess("Role management"),
        },
        {
          featureName: "Group Management",
          text: "Group Management",
          pathname: "/group-management",
          haveAccess: checkAccess("Group management"),
        },
        {
          featureName: "System Admin",
          text: "Vendor Admin",
          pathname: "/vendor/list",
          haveAccess: checkAccess("Vendor Management"),
        },
        {
          featureName: "System Setup",
          text: "System Setup",
          pathname: "/system-setup",
          haveAccess: checkAccess("External System"),
        },
        {
          featureName: TENANT_MANAGEMENT,
          text: TENANT_MANAGEMENT,
          pathname: "/tenant-management",
          haveAccess: checkAccess(TENANT_MANAGEMENT),
        },
      ],
    },
  ];
  let dataSecurityItems = menuItems[4]?.dataSecurity;
  let subfilterMenuItem = menuItems[5]?.menuItems;
  if (process.env.REACT_APP_AZURE_ENV === "true") {
    subfilterMenuItem?.splice(4, 0, {
      featureName: SERVICE_ACCOUNT_SETUP,
      text: SERVICE_ACCOUNT_SETUP,
      pathname: "/service-account-setup/list",
      haveAccess: checkAccess(SERVICE_ACCOUNT_SETUP),
    });
  }
  let filteredMenuItems = menuItems.filter(
    (Items) => Items.haveAccess === true
  );
  subfilterMenuItem = subfilterMenuItem?.filter(
    (item) => item.haveAccess === true
  );
  dataSecurityItems = dataSecurityItems?.filter(
    (item) => item.haveAccess === true
  );
  if (dataSecurityItems?.length > 0) {
    filteredMenuItems = [
      ...filteredMenuItems,
      { text: "Data Security", menuItems: dataSecurityItems },
    ];
  }
  if (subfilterMenuItem?.length > 0) {
    filteredMenuItems = [
      ...filteredMenuItems,
      { text: "Configuration", menuItems: subfilterMenuItem },
    ];
  }

  const checkactive = (item) => {
    const otherPaths = [
      {
        text: STUDY_SETUP,
        paths: [
          "/ExistingProtocol",
          "/ExistingStudyAssignment",
          "/import-assign-users",
        ],
      },
      {
        text: USER_MANAGEMENT,
        paths: ["/user-management/", "/user-management/add-user"],
      },
      {
        text: "Configuration",
        paths: [
          "/create-policy",
          "/policy-management/",
          "/create-role",
          "/role-management/",
          "/vendor/create",
          "/vendor/edit/",
          "/ExistingSystemAssignment",
          "/create-assign-system",
        ],
      },
      {
        text: "Profile",
        paths: ["/profile/"],
      },
    ];
    const tabhigh = item?.pathname
      ? item?.pathname === pathname
      : item?.menuItems?.some((item) => item?.pathname === pathname);
    const activePath = otherPaths.some((element) => {
      if (element?.text === item.text)
        return element?.paths.some((path) => pathname.search(path) >= 0);
      return tabhigh;
    });

    return activePath;
  };
  useEffect(() => {
    if (alertStore?.showAppSwitcher) {
      setpanelOpen(true);
    }
  }, [alertStore]);

  const toggleMenu = () => {
    if (alertStore.isFormComponentActive && panelOpen === false) {
      dispatch(hideAppSwitcher());
      dispatch(showAlert());
    }
    // eslint-disable-next-line no-shadow
    if (alertStore.isFormComponentActive === false || undefined) {
      setpanelOpen((panelOpen) => !panelOpen);
    }
  };

  const helpFile = () => {
    window.open(process.env.REACT_APP_HELP_ICON_URL, "_blank");
  };

  const ConfirmModal = React.memo(({ showVersionModal, closeModal }) => {
    return (
      <Modal
        open={showVersionModal}
        disableBackdropClick={true}
        onClose={closeModal}
        message={
          <div>
            <div>Clinical Data Analytics Suite</div>
            <p>Version 1.0</p>
          </div>
        }
        buttonProps={[{ label: "Close", onClick: closeModal }]}
        id="neutral"
      />
    );
  });
  const errorClassName =
    messageContext.errorMessage.messages === "No data changed."
      ? "noData"
      : messageContext.errorMessage.top;
  const tenantempty = tenantName === "";
  return (
    <>
      <Modal
        open={modalOpen}
        className="tenat-selection"
        title="Select a tenant"
        hideButtons={false}
        disableBackdropClick={true}
        buttonProps={[
          {
            label: "Select tenant",
            variant: "primary",
            onClick: updateTenantId,
            disabled: tenantempty,
          },
        ]}
        id="tenat-selection"
      >
        <Select
          size="small"
          fullWidth
          label="You are linked to multiple tenants"
          placeholder="Choose a tenant"
          value={tenantName}
          canDeselect={false}
          onChange={(e) => handleSelection(e)}
        >
          {tenantData?.map((option) => (
            <MenuItem key={option.tenant_nm} value={option.tenant_nm}>
              {option.tenant_nm}
            </MenuItem>
          ))}
        </Select>
      </Modal>
      <ConfirmModal
        showVersionModal={showVersionModal}
        closeModal={() => setShowVersionModal(false)}
      />
      <CancelConfirmModal
        confirmModalOpen={confirmModalOpen}
        moveBack={moveBack}
        moveAhead={moveAhead}
      />
      <div id="topNavbar">
        <Backdrop style={{ zIndex: 1 }} open={open}>
          <CircularProgress variant="indeterminate" size="small" />
        </Backdrop>
        <Banner
          variant="error"
          open={notLoggedOutErr}
          onClose={() => setNotLoggedOutErr(false)}
          message="Error: There is some error in logging out!"
        />
        <NavigationBar
          LogoComponent={() => (
            <div className={classes.centerAligned}>
              <Button onClick={toggleMenu} className={classes.fullNavHeight}>
                <App className={classes.appIcon} />
              </Button>
              <Typography
                className={classes.navLogo}
                onClick={() => history.push("/launchpad")}
              >
                IQVIA™
                <span style={{ paddingLeft: 3 }} className={classes.bold}>
                  Clinical Data Analytics Suite
                </span>
              </Typography>
            </div>
          )}
          position="static"
          menuItems={
            updatePathName[1] === "profile" && updatePathName[2] !== "core"
              ? []
              : filteredMenuItems
          }
          // profileMenuProps={profileMenuProps}
          // eslint-disable-next-line no-shadow
          onClick={({ pathname }) => history.push(pathname)}
          className={
            // eslint-disable-next-line prefer-template
            "nav"
          }
          checkIsActive={(item) => checkactive(item)}
          waves
          // notificationsMenuProps={notificationsMenuProps}
          otherButtons={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <div className={classes.centerAligned}>
                <Button
                  // onClick={() => setShowVersionModal(true)}
                  // className={classes.fullNavHeight}
                  onClick={helpFile}
                >
                  <Question className={classes.appIcon} />
                </Button>
              </div>
              {navigationOpen && (
                <ProfileMenu
                  logout={LogOut}
                  isActive={checkactive({
                    text: "Profile",
                  })}
                />
              )}
            </>
          }
        />
        {navigationOpen && (
          <NavigationPanel open={panelOpen} onClose={onPanelClose} />
        )}
      </div>
      <Banner
        variant={messageContext.errorMessage.variant}
        open={messageContext.errorMessage.show}
        onClose={messageContext.bannerCloseHandle}
        message={messageContext.errorMessage.messages}
        id={`Message-Banner--${messageContext.errorMessage.variant}`}
        className={`Message-Banner top-${errorClassName}`}
      />
    </>
  );
};

export default withRouter(AppHeader);
