import { put, call } from "redux-saga/effects";
import axios from "axios";

import {
  baseURL,
  STUDY_MEMIC_DATA,
  TENANT_MEMIC_SUCCESS,
  TENANT_MEMIC_FAILURE,
  PRODUCTS_LIST_FETCH,
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_LIST_FAILURE,
} from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export function* fetchTenantMemicData(params) {
  const { data } = params;
  try {
    const fetchSBData = yield call(
      axios.post,
      `${baseURL}/${STUDY_MEMIC_DATA}`,
      data
    );
    if (data?.table_name === "tenant") {
      yield put({
        type: TENANT_MEMIC_SUCCESS,
        tenantmemic: fetchSBData.data.data,
      });
      return;
    }
  } catch (e) {
    yield put({ type: TENANT_MEMIC_FAILURE, message: e.message });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchProducts() {
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${PRODUCTS_LIST_FETCH}`
    );
    yield put({
      type: PRODUCTS_LIST_SUCCESS,
      productList: fetchData.data.data,
    });
  } catch (e) {
    yield put({ type: PRODUCTS_LIST_FAILURE, message: e.message });
  }
}
