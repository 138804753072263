/* eslint-disable import/prefer-default-export */
export const IDLE_LOGOUT_TIME = 1800000;
export const Pages = {
  study: "study",
  system: "system",
  users: "users",
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
