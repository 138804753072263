/* eslint-disable */
import Blade from "apollo-react/components/Blade";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { serverSideWebPage } from "../../../services/ApiServices";

const NavigationPanel = ({ location: { pathname }, open, onClose }) => {
  const [openPanel, setOpenPanel] = useState(open);
  const [htmlContent, setHtml] = useState("");
  const closePanel = () => {
    setOpenPanel(false);
    onClose();
  };

  const onChange = () => {
    console.log("onChange");
  };
  useEffect(() => {
    setOpenPanel(open);
  }, [open]);
  useEffect(
    () => {
      setOpenPanel(false);
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  /**Used to set the sanitize html and prevent xss attack clicks */

  useEffect(async () => {
    const htmlTemp = await serverSideWebPage();
    const dirtyHtml = htmlTemp;
    const allowedDomains = "iqvia.com";
    DOMPurify.addHook("afterSanitizeAttributes", function (node) {
      if ("onclick" in node) {
        const urlClick = node?.getAttribute("onclick");
        if (urlClick) {
          let splitArray = urlClick?.split("=");
          if (!splitArray[1]?.includes(allowedDomains)) {
            node.removeAttribute("onclick");
          }
          const newtabs = process.env.REACT_APP_NEW_TAB_ALLOWED?.split(",");
          if (newtabs?.includes(node?.innerText)) {
            node.setAttribute(
              "onclick",
              `window.open(${splitArray[1]},'_blank')`
            );
          }
        }
      }
    });

    const staniziedOptions = {
      ADD_ATTR: ["onclick"],
      FORCE_BODY: true,
    };
    const cleanHtml = DOMPurify.sanitize(dirtyHtml, staniziedOptions);
    setHtml(cleanHtml);
  }, []);

  /** Used to set the app switcher close button with react component code */
  const domConentUpdate = (element) => {
    const documentElement = element;
    if (documentElement) {
      const appSwitcherButton = documentElement.querySelector(".switcher-icon");
      appSwitcherButton?.addEventListener("click", closePanel.bind(this));
    }
  };

  return (
    <>
      <Blade
        className="navigation-panel"
        width={331}
        hasBackdrop
        BackdropProps={{
          onClick: closePanel,
          style: { background: "transparent" },
        }}
        onChange={onChange}
        open={openPanel}
        onClose={closePanel}
        title=""
      >
        <div
          ref={(el) => {
            domConentUpdate(el);
          }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Blade>
    </>
  );
};

export default withRouter(NavigationPanel);
