import produce from "immer";
import {
  TENANT_MEMIC,
  TENANT_MEMIC_SUCCESS,
  PRODUCTS_LIST,
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_LIST_FAILURE,
} from "../../constants";

export const initialState = {
  productList: [],
  loading: false,
  tenantLoading: false,
  tenantmemic: "",
};

const TenantReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case TENANT_MEMIC:
        newState.tenantLoading = true;
        break;
      case TENANT_MEMIC_SUCCESS:
        newState.tenantmemic = action.tenantmemic;
        newState.tenantLoading = false;
        break;
      case PRODUCTS_LIST:
        newState.loading = true;
        break;
      case PRODUCTS_LIST_SUCCESS:
        newState.loading = false;
        newState.productList = action.productList;
        break;
      case PRODUCTS_LIST_FAILURE:
        newState.loading = false;
        break;

      default:
        break;
    }
  });

export default TenantReducer;
