// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../components/Icons/bg-image.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), linear-gradient(#595959, #999999);\n  width: 100vw;\n  background-position: center;\n  background-size: cover;\n  color: #000000;\n  height: 100vh;\n}\n\n#errorLogout h3 button {\n  display: none !important;\n}\n#errorLogout .MuiDialog-paperWidthSm {\n  width: 443px !important;\n}\n#errorLogout button {\n  padding: 0 29px;\n}", "",{"version":3,"sources":["webpack://src/pages/Logout/Logout.scss"],"names":[],"mappings":"AAAA;EACE,4FAAA;EAEA,YAAA;EACA,2BAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AAAF;;AAGE;EACA,wBAAA;AAAF;AAEE;EACA,uBAAA;AAAF;AAEE;EACA,eAAA;AAAF","sourcesContent":[".wrapper {\n  background-image: url(\"../../components/Icons/bg-image.png\"),\n    linear-gradient(#595959, #999999);\n  width: 100vw;\n  background-position: center;\n  background-size: cover;\n  color: #000000;\n  height: 100vh;\n}\n#errorLogout {\n  h3 button {\n  display: none !important;\n  }\n  .MuiDialog-paperWidthSm {\n  width: 443px !important;\n  }\n  button {\n  padding: 0 29px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
