import produce from "immer";
import {
  SERVICEACCOUNTDATA_FETCH_FAILURE,
  SERVICEACCOUNTDATA_FETCH_SUCCESS,
  SERVICEACCOUNT_DATA,
  SERVICEACCOUNTDETAILS,
  SERVICEACCOUNTDETAILS_FETCH_SUCCESS,
  SERVICEACCOUNTDETAILS_FETCH_FAILURE,
  SERVICEACCOUNTSTUDY,
  SERVICEACCOUNTSYSTEM,
  SERVICEACCOUNT_STUDY_FETCH_SUCCESS,
  SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS,
  SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE,
  CLEANUP,
} from "../../constants";

export const initialState = {
  productList: [],
  loading: false,
  serviceAccountLoading: false,
  errorMessage: "",
  accountDetails: [],
  serviceAccountStudies: [],
  serviceAccountSystems: [],
};

const ServiceAccountReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case SERVICEACCOUNT_DATA:
        newState.loading = true;
        break;
      case SERVICEACCOUNTDATA_FETCH_SUCCESS:
        newState.loading = false;
        newState.productList = action.productList;
        newState.serviceDataFetchSuccess = true;
        newState.errorMessage = "";
        newState.serviceDataFetchFailure = false;
        break;
      case SERVICEACCOUNTDATA_FETCH_FAILURE:
        newState.loading = false;
        newState.errorMessage = action.message;
        newState.productList = [];
        break;
      case SERVICEACCOUNTDETAILS:
        newState.loading = true;
        break;
      case CLEANUP:
        newState.accountDetails = [];
        newState.serviceAccountStudies = [];
        newState.serviceAccountSystems = [];
        break;
      case SERVICEACCOUNTDETAILS_FETCH_SUCCESS:
        newState.loading = false;
        newState.accountDetails = action.accountDetails;
        break;
      case SERVICEACCOUNTDETAILS_FETCH_FAILURE:
        newState.loading = false;
        newState.errorMessage = action.message;
        newState.accountDetails = [];
        break;
      case SERVICEACCOUNTSTUDY:
        newState.loading = true;
        break;
      case SERVICEACCOUNTSYSTEM:
        newState.loading = true;
        break;
      case SERVICEACCOUNT_STUDY_FETCH_SUCCESS:
        newState.loading = false;
        newState.serviceAccountStudies = action.serviceAccountStudies;
        break;
      case SERVICEACCOUNT_SYSTEM_FETCH_SUCCESS:
        newState.loading = false;
        newState.serviceAccountSystems = action.serviceAccountSystems;
        break;
      case SERVICEACCOUNT_STUDYANDROLE_FETCH_FAILURE:
        newState.loading = false;
        newState.errorMessage = action.message;
        newState.serviceAccountStudies = [];
        newState.serviceAccountSystems = [];
        break;

      default:
        break;
    }
  });

export default ServiceAccountReducer;
