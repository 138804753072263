/* eslint-disable import/prefer-default-export */
import { combineReducers } from "redux";
import StudyBoardReaducer from "./StudyBoardReducer";
import PolicyReducer from "./PolicyReducer";
import VendorReducer from "./VendorReducer";
import RolesReducer from "./RolesReducer";
import AlertReducer from "./AlertReducer";
import UserReducer from "./UserReducer";
import TenantReducer from "./TenantReducer";
import ServiceAccountReducer from "./ServiceAccountReducer";
import ObjectReducer from "./ObjectReducer";
import RowLevelReducer from "./RowLevelReducer";
import GroupReducer from "./GroupReducer";

export const appReducer = combineReducers({
  studyBoard: StudyBoardReaducer,
  policy: PolicyReducer,
  vendor: VendorReducer,
  Roles: RolesReducer,
  Alert: AlertReducer,
  user: UserReducer,
  tenant: TenantReducer,
  serviceAccount: ServiceAccountReducer,
  objectLevel: ObjectReducer,
  rowLevel: RowLevelReducer,
  group: GroupReducer,
});
