import ApolloThemeProvider from "apollo-react/utils/ApolloThemeProvider";
import React from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import "./App.scss";
import AppProvider from "./components/Providers/AppProvider";
import MessageProvider from "./components/Providers/MessageProvider";
import RoutesWrapper from "./RoutesWrapper";

const App = () => {
  const history = useHistory();
  return (
    <>
      <ApolloThemeProvider>
        <AppProvider>
          <MessageProvider>
            <BrowserRouter basename="/" history={history}>
              <RoutesWrapper />
            </BrowserRouter>
          </MessageProvider>
        </AppProvider>
      </ApolloThemeProvider>
    </>
  );
};

export default App;
