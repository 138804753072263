import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  OBJECTLEVEL_OBJECT_FETCH,
  OBJECTLEVEL_SCHEMA_FETCH,
  OBJECT_LIST__FETCH_FAILURE,
  OBJECT_LIST__FETCH_SUCCESS,
  PULL_SNOWFLAKE_OBJECTS,
  PULL_SNOWFLAKE_OBJECTS_FAILURE,
  PULL_SNOWFLAKE_OBJECTS_SUCCESS,
  SCHEMA_LIST_FETCH_FAILURE,
  SCHEMA_LIST_FETCH_SUCCESS,
  baseURL,
} from "../../constants";

export function* fetchSchemas(action) {
  const { userId } = action;
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${OBJECTLEVEL_SCHEMA_FETCH}`,
      { params: { userId } }
    );
    yield put({
      type: SCHEMA_LIST_FETCH_SUCCESS,
      schemaList: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({
      type: SCHEMA_LIST_FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchObjects(action) {
  const { userId } = action;
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${OBJECTLEVEL_OBJECT_FETCH}`,
      { params: { userId } }
    );
    yield put({
      type: OBJECT_LIST__FETCH_SUCCESS,
      objectList: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({
      type: OBJECT_LIST__FETCH_FAILURE,
      message: e.response.data.message,
    });
  }
}

export function* fetchSnowflakeObjectsOnDemand(action) {
  const { schemaName } = action;
  try {
    yield call(axios.get, `${baseURL}/${PULL_SNOWFLAKE_OBJECTS}`, {
      params: { schema: schemaName },
    });
    yield put({
      type: PULL_SNOWFLAKE_OBJECTS_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: PULL_SNOWFLAKE_OBJECTS_FAILURE,
      message: e.response.data.message,
    });
  }
}
