import Card from "apollo-react/components/Card";
import Link from "apollo-react/components/Link";
import Typography from "apollo-react/components/Typography";

const SDAFailureScreen = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        interactive
        style={{
          maxWidth: 450,
          minWidth: 350,
          height: 200,
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <Typography variant="title1" gutterBottom>
          SDA Authorization Failed
        </Typography>
        <Typography variant="body2" style={{ marginTop: "2rem" }}>
          Please Contact
          <span>
            <Link href="mailto:CDASSUPPORT@iqvia.com" size="small">
              {` CDAS Support`}
            </Link>
          </span>
        </Typography>
      </Card>
    </div>
  );
};

export default SDAFailureScreen;
