import axios from "axios";
import { call, put } from "redux-saga/effects";
import {
  ROLE_FETCH,
  ROLE_LIST_FAILURE,
  ROLE_LIST_FETCH,
  ROLE_LIST_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_STATUS_FAILURE,
  baseURL,
} from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export function* fetchRoles() {
  try {
    const { data } = yield call(axios.get, `${baseURL}/${ROLE_FETCH}`, {});
    yield put({
      type: ROLE_LIST_SUCCESS,
      roles: data.data,
    });
  } catch (e) {
    yield put({ type: ROLE_LIST_FAILURE, message: e.message });
  }
}

export function* updateRoleStatus(params) {
  try {
    let fetchData;
    // eslint-disable-next-line prefer-const
    fetchData = yield call(axios.post, `${baseURL}/${UPDATE_ROLE}`, params);

    yield put({
      type: ROLE_LIST_FETCH, // UPDATE_ROLE_STATUS_SUCCESS,
      // response: fetchData.data,
    });
  } catch (e) {
    yield put({
      type: UPDATE_ROLE_STATUS_FAILURE,
      message: e.response.data.message,
    });
  }
}
