import axios from "axios";
import { call, put } from "redux-saga/effects";
import {
  FETCH_PERMISSIONS,
  USER_PERMISSIONS_FAILURE,
  USER_PERMISSIONS_SUCCESS,
  baseURL,
} from "../../constants";
import { getUserId } from "../../utils";

// eslint-disable-next-line import/prefer-default-export
export function* fetchPermissions() {
  try {
    const userId = getUserId();
    const fetchData = yield call(
      axios.post,
      `${baseURL}/${FETCH_PERMISSIONS}`,
      {
        userId,
        productName: "Admin",
      }
    );

    yield put({
      type: USER_PERMISSIONS_SUCCESS,
      response: fetchData.data?.data || fetchData.data,
    });
  } catch (e) {
    yield put({ type: USER_PERMISSIONS_FAILURE, message: e.message });
  }
}
