import produce from "immer";
import {
  CLEANUP,
  COLUMN_LIST_FETCH_FAILURE,
  COLUMN_LIST_FETCH_SUCCESS,
  GET_COLUMN_LIST,
  GET_SUGGESTION_DATA,
  SUGGESTION_DATA_FETCH_FAILURE,
  SUGGESTION_DATA_FETCH_SUCCESS,
} from "../../constants";

export const initialState = {
  columnList: [],
  suggestionData: {},
  columns: [],
  loading: false,
  errmsg: "",
};

const RowLevelReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case GET_COLUMN_LIST:
        newState.loading = true;
        newState.columnList = [];
        newState.errmsg = "";
        break;
      case COLUMN_LIST_FETCH_SUCCESS:
        newState.loading = false;
        newState.columnList = action.columnList;
        break;
      case COLUMN_LIST_FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.columnList = [];
        break;
      case GET_SUGGESTION_DATA:
        newState.loading = true;
        newState.suggestionData = {};
        newState.columns = [];
        newState.errmsg = "";
        break;
      case SUGGESTION_DATA_FETCH_SUCCESS:
        newState.loading = false;
        newState.suggestionData = action.suggestionData;
        newState.columns = action.columns;
        break;
      case SUGGESTION_DATA_FETCH_FAILURE:
        newState.loading = false;
        newState.errmsg = action.message;
        newState.columns = [];
        break;
      case CLEANUP:
        newState.columnList = [];
        newState.suggestionData = {};
        newState.columns = [];
        break;
      default:
        break;
    }
  });

export default RowLevelReducer;
